var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-6"},[(_vm.loading)?_c('div',{staticClass:"loading"},[_c('v-dialog',{attrs:{"outlined":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"#181818","outlined":"","dark":""}},[_c('v-card-text',{staticClass:"mt-2 text-center"},[_vm._v(" Loading beta signees... "),_c('v-progress-linear',{staticClass:"mb-2 mt-2",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h1',{staticClass:"mb-md-8"},[_vm._v("Manage beta signees")])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"card-container lightgray"},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold text--primary"},[_vm._v("APPROVED")]),_c('v-data-table',{staticClass:"lightgray",attrs:{"headers":_vm.approvedHeaders,"items":_vm.approvedBetaSignees,"sort-by":_vm.columnNameApproved,"sort-desc":_vm.isDescendingApproved},on:{"update:sortBy":function($event){_vm.columnNameApproved=$event},"update:sort-by":function($event){_vm.columnNameApproved=$event},"update:sortDesc":function($event){_vm.isDescendingApproved=$event},"update:sort-desc":function($event){_vm.isDescendingApproved=$event}},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.created))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.approved)?_c('v-btn',{attrs:{"outlined":"","x-small":""},on:{"click":function($event){return _vm.approveBetaSignee(item.id)}}},[_vm._v("Approve")]):_c('span',[_vm._v(" "+_vm._s(_vm.getFormattedDate(item.date_approved))+" ")])]}},{key:"footer.page-text",fn:function(){return [_c('v-card',{staticClass:"ml-n2 mr-n2",attrs:{"tile":""}},[_c('JsonCSV',{attrs:{"data":_vm.approvedBetaSignees,"labels":_vm.csvLabels,"fields":_vm.csvFields,"name":_vm.csvTitleApproved}},[_c('v-btn',{attrs:{"outlined":"","small":""}},[_vm._v("Download CSV")])],1)],1)]},proxy:true}],null,true)})],1)],1)],1)],1)],1)],1),_c('br'),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"card-container lightgray"},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold text--primary"},[_vm._v("AWAITING APPROVAL")]),_c('v-data-table',{staticClass:"lightgray",attrs:{"headers":_vm.waitingHeaders,"items":_vm.waitingBetaSignees,"sort-by":_vm.columnNameWaiting,"sort-desc":_vm.isDescendingWaiting},on:{"update:sortBy":function($event){_vm.columnNameWaiting=$event},"update:sort-by":function($event){_vm.columnNameWaiting=$event},"update:sortDesc":function($event){_vm.isDescendingWaiting=$event},"update:sort-desc":function($event){_vm.isDescendingWaiting=$event}},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.created))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.approved)?_c('v-btn',{attrs:{"outlined":"","x-small":""},on:{"click":function($event){return _vm.approveBetaSignee(item.id)}}},[_vm._v("Approve")]):_vm._e(),(!item.approved)?_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","x-small":""},on:{"click":function($event){return _vm.toggleConfirmDelete(item.id)}}},[_vm._v(" Delete ")]):_c('span',[_vm._v(" "+_vm._s(_vm.getFormattedDate(item.date_approved))+" ")])]}},{key:"footer.page-text",fn:function(){return [_c('v-card',{staticClass:"ml-n2 mr-n2",attrs:{"tile":""}},[_c('JsonCSV',{attrs:{"data":_vm.waitingBetaSignees,"labels":_vm.csvLabels,"fields":_vm.csvFields,"name":_vm.csvTitleWaiting}},[_c('v-btn',{attrs:{"outlined":"","small":""}},[_vm._v("Download CSV")])],1)],1)]},proxy:true}],null,true)})],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"540"},model:{value:(_vm.confirmDeleteDialog),callback:function ($$v) {_vm.confirmDeleteDialog=$$v},expression:"confirmDeleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Are you sure you want to delete this user? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mb-3",attrs:{"outlined":"","text":""},on:{"click":function($event){_vm.deleteBetaSignee(); _vm.toggleConfirmDelete('')}}},[_vm._v(" Yes ")]),_c('v-btn',{staticClass:"mb-3",attrs:{"outlined":"","text":""},on:{"click":function($event){return _vm.toggleConfirmDelete('')}}},[_vm._v(" No ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }