










































































































import { Component, Vue } from 'vue-property-decorator';
import { readAdminApprovedBetaSignees, readAdminWaitingBetaSignees } from '@/store/admin/getters';
import { dispatchGetBetaSignees, dispatchApproveBetaSignee, dispatchDeleteBetaSignee } from '@/store/admin/actions';
import JsonCSV from 'vue-json-csv';

@Component({
  components: {
    JsonCSV,
  }
})
export default class AdminBetaSignees extends Vue {
  public loading = true;
  public columnNameApproved = 'created'
  public columnNameWaiting = 'created'
  public isDescendingApproved = true;
  public isDescendingWaiting = true;
  public approvedHeaders = [
    {
      text: 'Name',
      sortable: true,
      value: 'full_name',
      align: 'left',
    },
    {
      text: 'Email',
      sortable: true,
      value: 'email',
      align: 'left',
    },
    {
      text: 'Date requested',
      sortable: true,
      align: 'left',
      value: 'created',
      width: '50px',
    },
    {
      text: 'Approval date',
      value: 'actions',
      align: 'end',
    },
  ];

  public waitingHeaders = [
    {
      text: 'Name',
      value: 'full_name',
      align: 'left',
    },
    {
      text: 'Email',
      value: 'email',
      align: 'left',
    },
    {
      text: 'Occupation',
      value: 'occupation',
      align: 'left',
    },
    {
      text: 'Usage description',
      value: 'usage_reason',
      align: 'left',
      width: '350px',
    },
    {
      text: 'Date requested',
      align: 'left',
      value: 'created',
      width: '50px',
    },
    {
      text: 'Actions',
      value: 'actions',
      align: 'end',
    },
  ];

  public userToBeDeleted = '';
  public confirmDeleteDialog = false;

  get approvedBetaSignees() {
    return readAdminApprovedBetaSignees(this.$store);
  }

  get waitingBetaSignees() {
    return readAdminWaitingBetaSignees(this.$store);
  }

  public async mounted() {
    await dispatchGetBetaSignees(this.$store);
    this.loading = false;
  }

  public async approveBetaSignee(id: string) {
    await dispatchApproveBetaSignee(this.$store, { id: id });
    await dispatchGetBetaSignees(this.$store);
  }

  public getFormattedDate(date) {
    return new Date(date).toLocaleDateString();
  }

  public async deleteBetaSignee() {
    await dispatchDeleteBetaSignee(this.$store, this.userToBeDeleted);
  }

  public async toggleConfirmDelete(id: string) {
    this.userToBeDeleted = id;
    this.confirmDeleteDialog = !this.confirmDeleteDialog;
  }

  // CSV-download
  public csvTitleWaiting = 'beta_signees_waiting.csv';
  public csvTitleApproved = 'beta_signees_approved.csv';
  public csvLabels = {
    full_name: 'full_name',
    email: 'email',
    occupation: 'occupation',
    usage_reason: 'usage_reason',
    created: 'created',
  }
  public csvFields = ['full_name', 'email', 'occupation', 'usage_reason', 'created'];
}
